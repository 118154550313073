




































import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";

@Component({})
export default class RadioField extends Vue {
  @Prop() field?: any;
  @Prop() fieldName?: string;
  private selectedItem: string[] = [];

  @Watch("selectedItem")
  onSelectedItemsChanged(value: any[], oldValue: any[]) {
    this.$emit("input", value);
  }

  @Emit()
  input(event: any) {
    return event.target.value;
  }
}
