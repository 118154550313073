



























import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";

@Component({})
export default class RadioRangeField extends Vue {
  @Prop() field?: any;
  @Prop() fieldName?: string;
  private selectedValue = "";

  get NumberRange() {
    return [...Array(this.field._uiRange).keys()];
  }

  @Watch("selectedValue")
  onSelectedValueChange(value: string, oldValue: string) {
    this.$emit("input", value);
  }
}
