































































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class GenerateId extends Vue {
  @Prop() id?: string;

  get mailHref() {
    return (
      "mailto:someone@yoursite.com?subject=Diversity.me Generated Id&body=Your Diversity.me Id is --" +
      this.id
    );
  }

  CopyToClipboard(event: MouseEvent) {
    const input = document.getElementById("id-input") as HTMLInputElement;

    if (input) {
      /* Select the text field */
      input.select();
      input.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");

      input.setSelectionRange(0, 0);
      this.$bvToast.show("clipboard-copied");
    }
  }
}
