












import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";

@Component({})
export default class StringField extends Vue {
  @Prop() field?: any;
  @Prop() fieldName?: string;
  private selectedValue = "";

  getYears() {
    const end = new Date().getFullYear() - 15;
    return Array(end - (end - 80) + 1)
      .fill(0)
      .map((_, idx) => end - 80 + idx)
      .reverse();
  }

  get ValidationMessage() {
    return this.field._uiValidationMessage || "Please enter a value.";
  }

  @Watch("selectedValue")
  onSelectedValueChange(value: string, oldValue: string) {
    this.$emit("input", value);
  }
}
