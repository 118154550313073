




































































import { Component, Vue } from "vue-property-decorator";
import RadioField from "@/components/field-handlers/radio-field.vue";
import StringField from "@/components/field-handlers/string-field.vue";
import NumberField from "@/components/field-handlers/number-field.vue";
import SelectField from "@/components/field-handlers/select-field.vue";
import CheckboxField from "@/components/field-handlers/checkbox-field.vue";
import SingleCheckboxField from "@/components/field-handlers/single-checkbox-field.vue";
import YearPickerField from "@/components/field-handlers/yearpicker-field.vue";
import RadioRangeField from "@/components/field-handlers/radiorange-field.vue"

@Component({
  components: {
    RadioField,
    StringField,
    NumberField,
    SelectField,
    CheckboxField,
    SingleCheckboxField,
    YearPickerField,
    RadioRangeField
  },
})
export default class Survey extends Vue {
  private schema?: object | null = null;
  private result: object = {};
  private savingData = false;
  private loading = true;
  private failed = false;

  async getId() {
    const form = document.getElementById("survey-form") as HTMLFormElement;

    for (const item of document.getElementsByClassName("invalid")) {
      item.classList.remove("invalid");
    }

    if (form != null && !form.checkValidity()) {
      const scrollElement = form.querySelector(":invalid");

      if (scrollElement != null) {
        const scrollParent = scrollElement.parentElement;
        if (scrollElement != null && scrollParent != null) {
          const valFocus = scrollParent.attributes.getNamedItem(
            "validation-focus"
          );
          const valMessage = scrollParent.attributes.getNamedItem(
            "validation-element"
          );

          if (valFocus) {
            this.$scrollTo(
              document.getElementById(valFocus.value) as HTMLElement,
              500
            );
          } else {
            this.$scrollTo(scrollParent, 500);
          }

          if (valMessage) {
            (document.getElementById(
              valMessage.value
            ) as HTMLElement).classList.add("invalid");
          } else {
            scrollElement.classList.add("invalid");
          }
            return;
        }
      }
    }

    // Checkbox group validation
    for (const group of document.getElementsByClassName("checkbox-group")) {
        if (Array.from(group.getElementsByTagName("input")).filter(x => x.checked).length === 0) {
            this.$scrollTo(group);
            group.classList.add("invalid");
            return;
        }
    }

    this.savingData = true;
    const saveResponse = await this.$dataService.saveDiversityData(this.result);
    this.savingData = false;

    this.$router.push({ name: "GenerateId", params: { id: saveResponse.id } });
  }

  created() {
    this.$dataService
        .getLatestSchema()
        .then((data) => {
            this.schema = data;
        })
        .catch((except) => { this.failed = true; })
        .finally(() => { this.loading = false; });
  }
}
