















import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component({})
export default class StringField extends Vue {
  @Prop() field?: any;
  @Prop() fieldName?: string;

  get ValidationMessage() {
    return this.field._uiValidationMessage || "Please enter a value.";
  }
  @Emit()
  input(event: any) {
    return event.target.value;
  }
}
