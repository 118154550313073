







































import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Home extends Vue {
  async startSurvey() {
    // (optional) Wait until recaptcha has been loaded.
    //await this.$recaptchaLoaded();

    // Execute reCAPTCHA with action "login".
    //await this.$recaptcha("survey");
    this.$router.push("/survey");
  }
}
